body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.progress-ring__circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.camera_video_discover video {
  object-fit: cover !important;
  border-width: 1px !important;
  border-color: #505050 !important;
  border-radius: 15px !important;
}

#streaminPlayer {
  object-fit: contain !important;
  border-width: 1px !important;
  border-color: #505050 !important;
  border-radius: 15px !important;
}

.container {
  width: 100%;
  overflow-x: scroll;
}

.dialog {
  position: absolute;
  background-color: white;
  padding: 5px;
}

.dialog-bg {
  position: absolute;
  background-color: transparent;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
}

.paginationContainer {
  list-style: none;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  /* background-color: rgb(243, 243, 235); */
  margin-top: 15px;
}

.paginationActive {
  background-color: rgb(248, 234, 234);
  border-radius: 50%;  
}

.paginationItem {
  list-style: none;
  padding: 2px 12px;
  height: 31.5px;
  width: 31.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}


@tailwind base;
@tailwind components;
@tailwind utilities;